import { Platform } from 'react-native';
import { create } from 'zustand';
import {
    PathCategories,
    PathGpsCoordinates,
    Paths,
    PoiCategories,
    Pois,
    Aois,
    AoiCategories,
    AoiGpsCoordinates,
    SnowmobilePaths,
    SnowmobileSubPaths,
    LocalizedToken,
} from '@miqmap/shared';
import { AppContent } from '../interfaces';

interface Data {
    // Path data
    paths?: Paths;
    pathGpsCoordinates?: PathGpsCoordinates;
    pathCategories?: PathCategories;

    // PoI data
    pois?: Pois;
    poiCategories?: PoiCategories;

    // AoI data
    aois?: Aois;
    aoiCategories?: AoiCategories;
    aoiGpsCoordinates?: AoiGpsCoordinates;

    // Snowmobile path data
    snowmobilePaths?: SnowmobilePaths;
    snowmobileSubPaths?: SnowmobileSubPaths;

    // Area data
    areas?: {[key: string]: LocalizedToken};

    // Content filters
    mainContent: AppContent;
    additionalMapContent?: AppContent;
};

/**
 * Hook for accessing the path and poi data store
 */
const useDataStore = create<Data>((set) => {
    let mainContent: AppContent = {};
    let additionalMapContent: AppContent = {};

    switch (Platform.OS) {
        case 'web':
            //@ts-ignore
            const url = new URL(window.location.href);

            const mainContentString = url.searchParams.get('mainContent');
            mainContent = JSON.parse(mainContentString ?? '{}');

            const additionalMapContentString = url.searchParams.get('additionalMapContent');
            additionalMapContent = JSON.parse(additionalMapContentString ?? '{}');
            break;

        default:
            break;
    }

    return {
        mainContent,
        additionalMapContent,
    };
});

export { Data, useDataStore };