const colors = {
    white: '#ffffff',
    graytone1: '#f5f5f5',
    graytone2: '#eeeeee',
    graytone3: '#bbbbbb',
    graytone4: '#999999',
    graytone5: '#666666',
    black: '#000000',
    
    neutralColor50: '#e8ecf0',
    neutralColor100: '#d1dae1',
    neutralColor200: '#a3b5c4',
    neutralColor300: '#758fa6',
    neutralColor400: '#476a89',
    neutralColor500: '#19456b',
    neutralColor600: '#143756',
    neutralColor700: '#0f2940',
    neutralColor800: '#0a1c2b',
    neutralColor900: '#050e15',
       
    primaryColor50: '#e8f9f5',
    primaryColor100: '#d0f4eb',
    primaryColor200: '#a2e9d7',
    primaryColor300: '#73ddc2',
    primaryColor400: '#45d2ae',
    primaryColor500: '#16c79a',
    primaryColor600: '#129f7b',
    primaryColor700: '#0d775c',
    primaryColor800: '#09503e',
    primaryColor900: '#04281f',
    
    successColor50: '#edf7ee',
    successColor100: '#dbefdc',
    successColor200: '#b7dfb9',
    successColor300: '#94cf96',
    successColor400: '#70bf73',
    successColor500: '#4caf50',
    successColor600: '#3d8c40',
    successColor700: '#2e6930',
    successColor800: '#1e4620',
    successColor900: '#0f2310',
    
    warningColor50: '#fff5e6',
    warningColor100: '#ffeacc',
    warningColor200: '#ffd699',
    warningColor300: '#ffc166',
    warningColor400: '#ffad33',
    warningColor500: '#ff9800',
    warningColor600: '#cc7a00',
    warningColor700: '#995b00',
    warningColor800: '#663d00',
    warningColor900: '#331e00',
    
    errorColor50: '#feeceb',
    errorColor100: '#fdd9d7',
    errorColor200: '#fbb4af',
    errorColor300: '#f88e86',
    errorColor400: '#f6695e',
    errorColor500: '#f44336',
    errorColor600: '#c3362b',
    errorColor700: '#922820',
    errorColor800: '#621b16',
    errorColor900: '#310d0b',

    areaColor: '#82A996',
    warningAreaColor: '#BD2C16',
    importantAreaColor: '#EB6608'
};

const fonts = {
    light: 'Inter-Light',
    standard: 'Inter-Regular',
    bold: 'Inter-Bold',
    signature: 'Zodiak Variable',
};

export {colors, fonts};