import { Platform } from 'react-native';
import { create } from 'zustand';
import { FeatureType, PathDirection } from '@miqmap/map-content/src/enums';
import { FeatureGroup } from '@miqmap/shared';
import { AppOptions } from '../interfaces';

interface State {
    selectedType?: FeatureType;
    selectedId?: string;
    filters?: FeatureGroup[];

    sideBarOpen: boolean;
    focusOnMainContent: boolean;

    // Current orrientation of path direction
    pathDirection?: PathDirection;

    setSelected: (type?: FeatureType, id?: string) => void;
    setFilters: (filters: FeatureGroup[]) => void;
    setSideBarOpen: (open: boolean) => void;
    setPathDirection: (pathDirection?: PathDirection) => void;
};

/**
 * Hook for accessing the state store
 */
const useStateStore = create<State>((set) => {
    let options: AppOptions = {};

    switch (Platform.OS) {
        case 'web':
            //@ts-ignore
            const url = new URL(window.location.href);
            const optionsString = url.searchParams.get('options');
            options = JSON.parse(optionsString ?? '{}');
            break;

        default:
            break;
    }

    return {
        selectedType: options.startSelectedType,
        selectedId: options.startSelectedId,

        sideBarOpen: !!options.startOpen,
        focusOnMainContent: !!options.focusOnMainContent,

        setSelected: (selectedType?: FeatureType, selectedId?: string) => set({selectedType, selectedId}),
        setFilters: (filters: FeatureGroup[]) => set({filters}),
        setSideBarOpen: (sideBarOpen: boolean) => set({sideBarOpen}),
        setPathDirection: (pathDirection) => set({pathDirection}),
    };
});

export { State, useStateStore };