import { useEffect, useState } from "react";
import { View, ViewProps } from "react-native";
import { useTranslation } from "react-i18next";
import { shallow } from "zustand/shallow";
import {
    DifficultyVeryEasy,
    DifficultyEasy,
    DifficultyMedium,
    DifficultyHard,
    difficultyFilterEvaluator,
    FilteredList,
    LengthFilterOption,
    lengthFilterEvaluator,
    PathListContent,
    PoiListContent,
    AoiListContent,
    SnowmobileSubPathListContent,
    statusFilterEvaluator,
    StatusFilterOption,
    useThemedStyleFunction,
    withExternalStyle,
    Path,
    Poi,
    Aoi,
    LocalizedToken,
    PathDifficulty,
    FeatureType,
    PathCategoryId,
    SnowmobileSubPath,
    NavigationHeader,
} from "@miqmap/shared";
import { useDataStore, useStateStore } from "../../../stores";
import { sideBarContentStyle } from "./side-bar-content.style";
import { getTranslatedProperty } from "../../../localization";

type SideBarListProps = {
    navigation?: any;
} & ViewProps;

const SideBarList = (props: SideBarListProps) => {
    const { style: styleProp, navigation } = props;

    const [t] = useTranslation();
    
    // Get values and methods from stores
    const {
        paths,
        pathCategories,
        pois,
        poiCategories,
        aois,
        aoiCategories,
        snowmobileSubPaths,
        areas,
        mainContent,
    } = useDataStore(state => state, shallow);
    const {
        selectedId,
        setSelected,
        setFilters,
    } = useStateStore(state => state, shallow);

    // Get filtered data
    const filteredPaths = Object.values(paths ?? {})
        .filter((path: Path) => mainContent.pathCategories?.length === 0 || mainContent.pathCategories?.includes(path.pathCategoryId));
    const filteredPois = Object.values(pois ?? {})
        .filter((poi: Poi) => mainContent.poiCategories?.length === 0 || mainContent.poiCategories?.includes(poi.poiCategoryId));
    const filteredAois = Object.values(aois ?? {})
        .filter((aoi: Aoi) => mainContent.aoiCategories?.length === 0 || mainContent.aoiCategories?.includes(aoi.aoiCategoryId));
    const filteredSnowmobileSubPaths = Object.values(snowmobileSubPaths ?? {})
        .filter((subPath: SnowmobileSubPath) => mainContent.pathCategories?.includes(PathCategoryId.Snowmobiling));

    // Check how many unique categories we're rendering
    const uniquePathCategories = [...new Set(filteredPaths.map((path: Path) => path.pathCategoryId))];
    const uniquePoiCategories = [...new Set(filteredPois.map((poi: Poi) => poi.poiCategoryId))];
    const uniqueAoiCategories = [...new Set(filteredAois.map((aoi: Aoi) => aoi.aoiCategoryId))];

    const filters = [];

    // Add area filter if there's areas to chose from
    if (areas && Object.values(areas).length > 1) {
        const options = Object.values(areas)
        .map((area: LocalizedToken) => ({
            name: getTranslatedProperty('name', area),
            value: `${area.id}`,
        }))
        .sort((a, b) => {
            const al = a.name.toLowerCase();
            const bl = b.name.toLowerCase();

            return al < bl ? -1 : al > bl ? 1 : 0;
        });

        const evaluator = (entry: any, option: any) => {
            return !option || entry.areas.map((area: LocalizedToken) => `${area.id}`).includes(option);
        };

        filters.push({identifier: 'area', name: t('filters:area'), unsetName: t('filters:areaAll'), options, evaluator});
    }

    // Add length filter if objets with length are present
    if (uniquePathCategories.length > 0) {
        filters.push({
            identifier: 'length',
            name: t('filters:length'),
            unsetName: t('filters:lengthAll'),
            options: [
                {name: '0-3 km', value: LengthFilterOption.ZeroToThree},
                {name: '3-6 km', value: LengthFilterOption.ThreeToSix},
                {name: '6-10 km', value: LengthFilterOption.SixToTen},
                {name: '10+ km', value: LengthFilterOption.TenPlus},
            ],
            evaluator: lengthFilterEvaluator,
        });
    }

    // Add difficulty filter if objects with difficulty are present
    if (uniquePathCategories.length > 0) {
        filters.push({
            identifier: 'difficulty',
            name: t('filters:difficulty'),
            unsetName: t('filters:difficultyAll'),
            options: [
                {name: t('veryEasyPlural'), value: PathDifficulty.VeryEasy, icon: DifficultyVeryEasy},
                {name: t('easyPlural'), value: PathDifficulty.Easy, icon: DifficultyEasy},
                {name: t('mediumPlural'), value: PathDifficulty.Medium, icon: DifficultyMedium},
                {name: t('hardPlural'), value: PathDifficulty.Hard, icon: DifficultyHard},
            ],
            evaluator: difficultyFilterEvaluator,
        });
    }

    // Add status filter if objects with status are present
    if (uniquePathCategories.length > 0 || filteredSnowmobileSubPaths?.length) {
        filters.push({
            identifier: 'status',
            name: t('filters:status'),
            unsetName: t('filters:statusAll'),
            options: [
                {name: t('filters:statusOpen'), value: StatusFilterOption.Open },
                ...( uniquePathCategories.includes(PathCategoryId.CrossCountrySkiing) ? [
                    {name: t('filters:statusGroomedToday'), value: StatusFilterOption.GroomedToday},
                ] : []),
                {name: t('filters:statusClosed'), value: StatusFilterOption.Closed },
                ...( uniquePathCategories.includes(PathCategoryId.CrossCountrySkiing) ? [
                    {name: t('filters:statusGroomedYesterdayOrToday'), value: StatusFilterOption.GroomedLatestYesterday},
                ] : []),
            ],
            evaluator: statusFilterEvaluator,
        });
    }

    // Register data providers on data change
    const [dataProviders, setDataProviders] = useState<any[]>([]);
    useEffect(() => {
        setDataProviders([
            ...(filteredPaths ? [{ entries: Object.values(filteredPaths), component: PathListContent }] : []),
            ...(filteredSnowmobileSubPaths ? [{ entries: Object.values(filteredSnowmobileSubPaths), component: SnowmobileSubPathListContent }] : []),
            ...(filteredPois ? [{ entries: Object.values(filteredPois).map((poi: Poi) => ({
                    ...poi,
                    // Add additional category data
                    ...(poiCategories ? {category: poiCategories[poi.poiCategoryId]} : {})
                })),
                component: PoiListContent,
            }] : []),
            ...(filteredAois ? [{ entries: Object.values(filteredAois).map((aoi: Aoi) => ({
                    ...aoi,
                    ...(aoiCategories ? {category: aoiCategories[aoi.aoiCategoryId]} : {})
                })),
                component: AoiListContent,
            }] : []),
        ]);
    }, [setDataProviders, paths, pois, snowmobileSubPaths, aois]);

    // Do navigation based on changes to selection
    useEffect(() => {
        if (selectedId !== undefined) {
            navigation?.navigate("details");
        }
        else {
            navigation?.navigate("list");
        }
    }, [navigation, selectedId]);

    const handleListClick = (providerIndex: number, entryIndex: number) => {
        const entry = dataProviders[providerIndex]?.entries[entryIndex];

        // Entry is a POI
        if (entry?.poiCategoryId) {
            console.log('POI', entry);
            setSelected(FeatureType.Poi, entry?.id);
        }
        // Entry is a Path
        else if (entry?.pathCategoryId) {
            console.log('Path', entry);
            setSelected(FeatureType.Path, entry?.id);
        }
        // Entry is a Snowmobile SubPath
        else if (entry?.subPathGPSCoordinates) {
            console.log('Snowmobile SubPath', entry);
            setSelected(FeatureType.SnowmobileSubPath, entry?.id);
        }
        // Entry is a Aoi
        else if (entry?.aoiCategoryId) {
            console.log('AOI', entry);
            setSelected(FeatureType.Aoi, entry?.id);
        }
    };

    const handleOnFiltered = (filteredDataProviders: any[]) => {
        const originalEntries = dataProviders.map((provider) => provider.entries).flat();
        const entries = filteredDataProviders.map((provider) => provider.entries).flat();

        // Check if list is unfiltered, set filters accordingly
        if (originalEntries.length == entries.length) {
            setFilters([]);
            return;
        }

        const pathIds: string[] = [];
        const poiIds: string[] = [];
        const aoiIds: string[] = [];
        const snowmobileSubPathIds: string[] = [];
        entries.forEach((entry: any) => {
            // Path
            if (entry.pathCategoryId !== undefined) {
                pathIds.push(entry.id);
            }
            // Poi
            else if (entry.poiCategoryId !== undefined) {
                poiIds.push(entry.id);
            }
            //Aoi
            else if (entry.aoiCategoryId !== undefined) {
                aoiIds.push(entry.id);
            }
            // Snowmobile SubPath
            else if (entry.subPathGPSCoordinates) {
                snowmobileSubPathIds.push(entry.id);
            }
        });

        setFilters([
            ...(pathIds.length > 0 ? [{type: FeatureType.Path, ids: pathIds}] : []),
            ...(poiIds.length > 0 ? [{type: FeatureType.Poi, ids: poiIds}] : []),
            ...(aoiIds.length > 0 ? [{type: FeatureType.Aoi, ids: aoiIds}] : []),
            ...(snowmobileSubPathIds.length > 0 ? [{type: FeatureType.SnowmobileSubPath, ids: snowmobileSubPathIds}] : []),
        ]);
    };

    const style = useThemedStyleFunction(sideBarContentStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    let title = t('destinationNamePlaceholder');
    // Get title if a unique path category is listed
    if (pathCategories && uniquePathCategories.length === 1 && uniquePoiCategories.length === 0 && filteredSnowmobileSubPaths.length === 0) {
        title = getTranslatedProperty('name', pathCategories[uniquePathCategories[0]]);
    }
    // Get title if no paths are listed but a unique PoI category is listed
    else if (poiCategories && uniquePathCategories.length === 0 && uniquePoiCategories.length === 1 && filteredSnowmobileSubPaths.length === 0) {
        title = getTranslatedProperty('name', poiCategories[uniquePoiCategories[0]]);
    }
 
    // Get title if no paths are listed but snowmobile paths is listed
    else if (pathCategories && uniquePathCategories.length === 0 && uniquePoiCategories.length === 0 && filteredSnowmobileSubPaths.length > 0) {
        title = getTranslatedProperty('name', pathCategories[PathCategoryId.Snowmobiling]);
    }

    // Get title if no paths, pois, snowmobile are listed but a unique AoI category is listed
    else if (aoiCategories && uniquePathCategories.length === 0 && uniquePoiCategories.length === 0 && filteredSnowmobileSubPaths.length === 0 
        && uniqueAoiCategories.length === 1 ) {
        title = getTranslatedProperty('name', aoiCategories[uniqueAoiCategories[0]]);
    }

    return (
        <View style={containerStyle}>
            <NavigationHeader title={title} leftAlign={true} />
            { areas && 
                <FilteredList style={style.list}
                    emptyText={t('filters:emptyResult')}
                    dataProviders={dataProviders}
                    filters={filters}
                    onPress={(pI: number, eI: number) => handleListClick(pI, eI)}
                    onFiltered={(dataProviders: any[]) => handleOnFiltered(dataProviders)}/>
            }
        </View>
    );
};

export { SideBarList };